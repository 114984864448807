<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
          <div class="card">
            <div class="card-body table-responsive table-head">
              <div class="row bor-bot pb-2">
                <div class="col-md-8 pl-0">
                  <h1 class="m-0 text-dark pt-2 pb-2">Financials Reports</h1>
                </div>
                <div class="col-md-4">
                  <div class="text-right pb-3">
                  <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                  <li class="breadcrumb-item"><Breadcrumbs/></li>
                  </ol>
                  </div>                  
                </div>
              </div>

              <b-modal id="modal-member-report" v-bind:hide-footer="true" ref="modal-member-report" size="lg"  title="Advanced Search">
                <form ref="form" method="post">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Transaction Date</label>
                         <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Transaction Id</label>
                         <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Status</label>
                         <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Member Id</label>
                         <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">First Name</label>
                         <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Last Name</label>
                         <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Email</label>
                         <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Transaction Start Date</label>
                        <div class="input-group birthdate">
                          <div class="birthdaypicker">
                            <date-pick
                                v-model="searchForm.transaction_start_date"
                                :format="'MM/DD/YYYY'"
                                :displayFormat="'MM/DD/YYYY'"
                                v-mask="'##/##/####'"
                                :parseDate="parseDatePick"
                            ></date-pick>
                          </div>
                        </div>
                        <input id="transaction_start_date" type="hidden" class="form-control" :value="transaction_start_date1">
                        <div class="text-sm text-danger">{{ errors.transaction_start_date }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">Registration End Date</label>
                        <div class="input-group birthdate">
                          <div class="birthdaypicker">
                            <date-pick
                                v-model="searchForm.transaction_end_date"
                                :format="'MM/DD/YYYY'"
                                :displayFormat="'MM/DD/YYYY'"
                                v-mask="'##/##/####'"
                                :parseDate="parseDatePick"
                            ></date-pick>
                          </div>
                        </div>
                        <input id="transaction_end_date" type="hidden" class="form-control" :value="transaction_end_date1">
                        <div class="text-sm text-danger">{{ errors.transaction_end_date }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="membership_id"  class="form-label">Product</label><br>
                        <select id="membership_id" name="membership_id" class="form-control" v-model="searchForm.membership_id">
                          <option value="">Select</option>
                          <option v-for="(id, val) in membershipData" :value="val" :key="val">{{ id }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="financials_sub_class"  class="form-label">Sub-Class</label><br>
                        <select id="financials_sub_class" name="financials_sub_class" class="form-control" v-model="searchForm.sub_class">
                          <option v-for="(id, val) in financialSubClass" :value="val" :key="val">{{ id }}</option>
                        </select>
                      </div>
                    </div>
                  <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-label">class</label>
                         <input type="text" class="form-control">
                      </div>
                    </div>
                    <div class="col-md-12 modal-searchbtn text-center">
                      <div class="form-group">
                        <button type="button" @click="advancedSearch();" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                      </div>
                      <div class="text-sm text-danger">{{ errors.not_found }}</div>
                    </div>
                  </div>
                </form>
              </b-modal>

              <div class="table-responsive mt-3">
                 <div class="data-tablebutn pb-3">
                    <b-button class="btn-primary btn-darkblue btn" v-b-modal.modal-member-report>Filter</b-button>
                  </div>
                <table class="table table-sm table-bordered table-hover" id="financialTable">
                  <thead class="thead-dark">
                  <tr>
                    <th>Transaction Date</th>
                    <th>Transaction Id</th>
                    <th>Status</th>
                    <th>Member Id</th>
                    <th>Team Name</th>
                    <th>E-Mail</th>
                    <th>Product</th>
                    <th>Cost</th>
                    <th>Class</th>
                    <th>Sub-Class</th>
                  </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import $ from 'jquery';
import DownloadExcel from "vue-json-excel";
import moment from 'moment';
import axios from 'axios';

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake = pdfMake;
import jsZip from 'jszip';
window.JSZip = jsZip;

const validateName = name => {
  if (name.length || name != "") {
    return { valid: true, error: null };
  }
  return { valid: false, error: null };
};

export default {
  name: "Financial",
  data() {
    return {
      table: {},
      searchForm: {
        transaction_start_date :'',
        transaction_end_date :'',
        membership_id: '',
        class: '',
        sub_class: '',
        member_id:''
      },
      button: {
        SearchButton: 'Search'
      },
    }
  },
  methods: {
    advancedSearch() {
      if(!this.validate()) {
        return;
      }
      console.log(this.button.SearchButton);
      this.button.SearchButton = 'Searching...';
      console.log(this.button.SearchButton);
      this.loaded = false;
      this.table.draw();
      this.$refs['modal-member-report'].hide();
      this.button.SearchButton = 'Search';
    },
    validate() {
      this.errors = {}
      this.valid =  false;
      const validStartDate = validateName(this.searchForm.transaction_start_date);
      this.errors.transaction_start_date = validStartDate.error;

      const validEndDate = validateName(this.searchForm.transaction_end_date);
      this.errors.transaction_end_date = validEndDate.error;

      var dateValid = false;
      if(validStartDate.valid && !this.validateDate(this.searchForm.transaction_start_date)){
        dateValid = true;
        this.errors.transaction_start_date = "Invalid Date";
      }
      if(validEndDate.valid && !this.validateDate(this.searchForm.transaction_end_date)){
        dateValid = true;
        this.errors.transaction_end_date = "Invalid Date";
      }

      if(dateValid == false){
        if((validStartDate.valid && validEndDate.valid) && (moment(this.searchForm.transaction_end_date).isBefore(this.searchForm.transaction_start_date))) {
          this.errors.registration_end_date = 'End date must be greater than Start date!';
          return this.valid =  false;
        } else {
          this.valid = true;
        }
      } else {
        this.valid = false;
      }

      return this.valid;
    },
  },
  mounted() {
    this.getMembership();
    this.getFinancialClass();
    this.getFinancialSubClass();
    console.log(this.membershipData);
    this.table = $("#financialTable").DataTable({
      dom: "<'row mt-2'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-4'B>"+
          "<'col-sm-12 col-md-4'f>><'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      buttons: [
        {
          extend: 'excel',
          className: 'btn-primary btn-darkblue btn',
          filename:"financial report",
          title:"USA Water Ski & Wake Sports financial report"
        },{
          extend: 'csv',
          className: 'btn-primary btn-darkblue btn',
          filename:"financial report",
          title:"USA Water Ski & Wake Sports financial report"
        },{
          extend: 'pdf', className: 'btn-primary btn-darkblue btn', orientation: 'landscape',
          pageSize:'legal', filename:"financial report", title:"USA Water Ski & Wake Sports financial report"
        },/*{
          text: 'Custom Btn',
          className: 'btn-primary btn-darkblue btn',
          action: function ( e, dt, node, config ) { lunchOut() }
        },*/
      ],
      processing: true,
      serverSide: true,
      "bFilter": true,
      ajax: {
        url: process.env.VUE_APP_URL + "api/report/financial/Club",
        type: 'post',
        data: function (d) {
          d.start_date = $('#transaction_start_date').val();
          d.end_date = $('#transaction_end_date').val();
          let memId = $('#membership_id').val();
          let subClass = $('#financials_sub_class').val();
          d.membership_id = (memId!=''?memId:subClass);
        }
      },
      columns: [
        {data: "transaction_date", name: "transaction_date", orderable: true, searchable: true},
        {data: "transaction_id", name: "transaction_id", orderable: true, searchable: true},
        {data: "status", name: "status", orderable: true, searchable: true},
        {data: 'confirmation_code', name: 'member.confirmation_code', orderable: true, searchable: true},
        {data: "team_name", name: "team.name", orderable: true, searchable: true},
        {data: "team_email", name: "team.email",
          render : function(data, type, row) {
            return '<a class="email-link" href="mailto:'+ data +'">'+ data +'</a>' || 'NA';
          },orderable: true, searchable: true},
        /*{data: "dob", name: "personalDetails.dob", orderable: true, searchable: true},
        {data: "gender", name: "personalDetails.gender", orderable: true, searchable: true},*/
        {data: "membership_name", name: "membership.name", orderable: true, searchable: true},
        {data: "cost", name: "cost", orderable: true, searchable: true},
        {data: "class", name: "registration.membership_id", orderable: true, searchable: true},
        {data: "subclass", name: "registration.membership_id", orderable: true, searchable: true}
      ],
      order: [[0, "desc"]]
    });
  },
  computed: {
    transaction_start_date1: function() {
      return this.searchForm.transaction_start_date;
    },
    transaction_end_date1: function() {
      return this.searchForm.transaction_end_date;
    }
  }
}

function lunchOut(){
  alert("test");
}
</script>

<style scoped>

</style>
